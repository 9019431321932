"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenButtonController = void 0;
var Dom_1 = require("common/Dom");
var Messenger_1 = require("common/Messenger");
var button_1 = require("constants/button");
var TokenOverlayController_1 = require("./TokenOverlayController");
var TokenModalController_1 = require("./TokenModalController");
var modal_1 = require("constants/modal");
var popup_1 = require("constants/popup");
var uaParser = require("ua-parser-js");
var isDesktop = !uaParser().device.type;
var TokenButtonController = /** @class */ (function () {
    function TokenButtonController(element, options) {
        var _this = this;
        this._enabled = true;
        this.createButtonIframe = function () {
            var _a = _this.buttonOptions, url = _a.url, height = _a.height, version = _a.version;
            if (_this._buttonIframe) {
                return;
            }
            _this._buttonIframe = Dom_1.Dom.createElement("iframe", TokenButtonController._attrs);
            Dom_1.Dom.applyAttrs(_this._buttonIframe, {
                src: "".concat(url, "/").concat(version, "/button.html"),
                height: height,
            });
            _this.containerElement.appendChild(_this._buttonIframe);
            _this._bindListeners();
            _this._bindEvents();
            return _this._buttonIframe;
        };
        this.setButtonClickHandler = function (asyncCall, syncCall) {
            if (syncCall.constructor.name === "AsyncFunction") {
                throw new Error("Async function is not allowed for redirect.");
            }
            _this._onClickSync = function (redirect) {
                var format = function (url, payload) {
                    var params = Object.keys(payload)
                        .filter(function (k) { return typeof payload[k] !== "undefined"; })
                        .map(function (k) {
                        if (typeof payload[k] === "object") {
                            return "".concat(k, "=").concat(encodeURIComponent(JSON.stringify(payload[k])));
                        }
                        return "".concat(k, "=").concat(encodeURIComponent(payload[k]));
                    });
                    redirect("".concat(url, "?").concat(params.join("&")));
                };
                var res = syncCall(format);
                if (res instanceof Promise) {
                    window.stop();
                    throw new Error("Async function is not allowed for redirect.");
                }
                return res;
            };
            _this._onClickAsync = function (done, errorCb) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, asyncCall(done, errorCb)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            }); }); };
            return _this;
        };
        this.destroy = function () {
            _this._unbindListeners();
            _this.containerElement.removeChild(_this._buttonIframe);
            _this._buttonIframe = null;
        };
        this.setEnabled = function (value) {
            _this._enabled = value;
        };
        this._bindEvents = function () {
            var overlay;
            var webappIframe;
            var messenger = _this._getMessengerInstance();
            var desktopType = _this.buttonOptions.desktopType;
            messenger.onMessage(button_1.BUTTON_EVENTS.BUTTON_CLICK, function () { return __awaiter(_this, void 0, void 0, function () {
                var _a, url /**SDK Url where button is hosted */, appUrl, version, doneCb, doneCb;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!(isDesktop && desktopType === button_1.DESKTOP_TYPES.MODAL_IFRAME)) return [3 /*break*/, 2];
                            _a = this.buttonOptions, url = _a.url, appUrl = _a.appUrl, version = _a.version;
                            overlay = new TokenOverlayController_1.TokenOverlayController({ url: url, version: version });
                            overlay.show();
                            webappIframe = new TokenModalController_1.TokenModalController({
                                url: appUrl,
                            });
                            webappIframe.show();
                            doneCb = function (url) {
                                webappIframe.updateAttrs({ src: url });
                            };
                            return [4 /*yield*/, this._onClickAsync(doneCb, function (err) {
                                    console.error("Error in button click handler", err);
                                    if (webappIframe)
                                        webappIframe.destroy();
                                    if (overlay)
                                        overlay.destroy();
                                    _this.destroy(); // Destroy previous button instance
                                    throw err;
                                })];
                        case 1:
                            _b.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            doneCb = function (url) {
                                window.location.replace(url);
                            };
                            this._onClickSync(doneCb);
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.SET_MODAL_STYLE, function (_a) {
                var data = _a.data;
                if (webappIframe) {
                    webappIframe.updateAttrs(data);
                }
            }, true);
            messenger.onMessage(popup_1.POPUP_EVENTS.BANK_POPUP_CLOSE, function (_a) {
                var data = _a.data;
                if (webappIframe) {
                    webappIframe.updateAttrs({ src: data });
                }
            }, true);
            messenger.onMessage(popup_1.POPUP_EVENTS.BANK_POPUP_VISIBILITY_CHANGE, function (_a) {
                var visible = _a.visible;
                if (overlay && !visible) {
                    overlay.destroy();
                }
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE, function () {
                if (overlay) {
                    overlay.destroy();
                }
            }, true);
        };
        this._getMessengerInstance = function () {
            var _a = _this.buttonOptions, id = _a.id, url = _a.url;
            if (!_this._messenger) {
                _this._messenger = new Messenger_1.Messenger({
                    id: id || "",
                    source: window,
                    destination: _this._buttonIframe.contentWindow,
                    origin: url,
                });
            }
            return _this._messenger;
        };
        this._onButtonLoad = function () {
            var messenger = _this._getMessengerInstance();
            messenger.dispatchMessage(__assign({ type: button_1.BUTTON_EVENTS.SET_BUTTON_STYLE }, _this.buttonOptions));
            messenger.dispatchMessage({
                type: button_1.BUTTON_EVENTS.SET_BUTTON_ENABLED,
                enabled: _this._enabled,
            });
        };
        this._bindListeners = function () {
            if (!_this._buttonIframeExists())
                return;
            _this._buttonIframe.addEventListener("load", _this._onButtonLoad);
        };
        this._unbindListeners = function () {
            _this._buttonIframe.removeEventListener("load", _this._onButtonLoad);
            _this._messenger.destroy();
        };
        this.containerElement = element;
        this.buttonOptions = options;
    }
    TokenButtonController.prototype._buttonIframeExists = function () {
        return this._buttonIframe instanceof window.Node;
    };
    TokenButtonController._attrs = {
        name: "token-button-iframe",
        frameBorder: 0,
        width: "100%",
        style: {
            position: "static",
            display: "inline",
        },
    };
    return TokenButtonController;
}());
exports.TokenButtonController = TokenButtonController;
