"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenController = void 0;
var TokenButtonController_1 = require("./TokenButtonController");
var Utils_1 = require("common/Utils");
var Messenger_1 = require("common/Messenger");
var modal_1 = require("constants/modal");
var button_1 = require("constants/button");
var TokenController = /** @class */ (function () {
    function TokenController(_a) {
        var _this = this;
        var url = _a.url, onSuccess = _a.onSuccess, onFailure = _a.onFailure, appUrl = _a.appUrl, sdkVersion = _a.sdkVersion;
        this.createTokenButton = function (element, options) {
            var url = _this._url;
            var appUrl = _this._appUrl;
            var buttonController = new TokenButtonController_1.TokenButtonController(element, __assign(__assign({ height: "45", desktopType: button_1.DESKTOP_TYPES.MODAL_IFRAME }, options), { url: url, version: _this._sdkVersion, appUrl: appUrl, id: Utils_1.Utils.generateNonce() }));
            var buttonIframe = buttonController.createButtonIframe();
            _this._registerMessages(buttonIframe.contentWindow);
            return buttonController;
        };
        this._registerMessages = function (destination) {
            var messenger = new Messenger_1.Messenger({
                destination: destination,
                source: window
            });
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE, function (_a) {
                var data = _a.data;
                _this._onSuccessCb(data);
            }, true);
        };
        this._url = url;
        this._appUrl = appUrl;
        this._sdkVersion = sdkVersion;
        this._id = Utils_1.Utils.generateNonce();
        this._onSuccessCb = onSuccess;
        this._onFailureCb = onFailure;
    }
    return TokenController;
}());
exports.TokenController = TokenController;
